/**
 * @generated SignedSource<<e9abe0ab020312dfdec779f6b31ac689>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfileSettingModalQuery$variables = {
  email: string;
  isNotSupportTotp: boolean;
};
export type UserProfileSettingModalQuery$data = {
  readonly user: {
    readonly id: string | null | undefined;
    readonly totp_activated: boolean | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"TOTPActivateModalFragment">;
  } | null | undefined;
};
export type UserProfileSettingModalQuery = {
  response: UserProfileSettingModalQuery$data;
  variables: UserProfileSettingModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isNotSupportTotp"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totp_activated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserProfileSettingModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TOTPActivateModalFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserProfileSettingModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0c9773ff4a3e759f0f9822463daa757",
    "id": null,
    "metadata": {},
    "name": "UserProfileSettingModalQuery",
    "operationKind": "query",
    "text": "query UserProfileSettingModalQuery(\n  $email: String!\n  $isNotSupportTotp: Boolean!\n) {\n  user(email: $email) {\n    id\n    totp_activated @skipOnClient(if: $isNotSupportTotp)\n    ...TOTPActivateModalFragment\n  }\n}\n\nfragment TOTPActivateModalFragment on User {\n  email\n  totp_activated @skipOnClient(if: $isNotSupportTotp)\n}\n"
  }
};
})();

(node as any).hash = "c87e74dfaa01d51a1697e67df81e101b";

export default node;
